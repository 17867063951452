import 'airbnb-browser-shims';

window.addEventListener('load', () => {


  $('[data-scrollto]').on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    const scrollToElement = e.currentTarget.getAttribute('data-scrollto');
    const header = $('header');
    let headerHeight = 0;
    if ($(header).length) {
      headerHeight = $(header)[0].clientHeight;
    }
    if ($(`.${scrollToElement}`).length) {
      $(window).scrollTop($(`.${scrollToElement}`).offset().top - headerHeight);
    }
  });


  $('[data-val-required]').each((e, element) => {
    const el = $(element).get(0);
    const elId = el.id;
    const elType = el.type;
    let label;
    if (elType === 'radio') {
      label = $(el).closest('.form-group').find('> label');
    } else if (elType === 'checkbox') {
      label = $(elType);
    } else {
      label = $(`[for="${elId}"]`);
    }
    label.addClass('required');
  });


});
